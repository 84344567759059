import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Header } from "./Header"
import '../css/main.css'
import { APIContext } from "../APIContext";

import { FiCheck } from "react-icons/fi"


class Layout extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            APIKey: undefined,
            isLoading: true,
            isCorrect: false,
            data: {},

            snackbarShow: false,
            snackbarTitle: undefined,
            snackbarContent: undefined,
        }
    }


    componentDidMount() {
        this.fetchData(this.getKey());
    }

    render() {
        return (
            <APIContext.Provider
                value={{
                    ...this.state,
                    setAPIKey: this.setAPIKey,
                    toggleSnackBar: this.toggleSnackBar
                    }}
            >
                <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => (
                <>
                    <Header siteTitle={data.site.siteMetadata.title} />
                    <div>
                        <main>{this.props.children}</main>
                    </div>

                    <div className={"card flex text-my_gray absolute whitespace-no-wrap overflow-hidden items-center bottom-0 right-0 p-1 px-2 m-5 " + (this.state.snackbarShow ? "scale-x-on-r" : "scale-x-off-r")}>
                        <div className="flex-auto">
                            <FiCheck className="text-my_yellow mr-2 text-2xl" />
                        </div>
                        <div className="flex-auto text-sm leading-tight">
                            <p className="text-my_yellow font-semibold uppercase">{this.state.snackbarTitle}</p>
                            {this.state.snackbarContent}
                        </div>

                    </div>
                </>
                )}
                />
            </APIContext.Provider>
        )
    }

    getKey = () => {
        if (localStorage.hasOwnProperty("API_Key"))
            return localStorage.getItem("API_Key");
        return undefined;
    }

    fetchData = (key) => {
        this.setState({
            isLoading: true
        });
        if(key != undefined && key != null && key != "") {
            fetch("https://api.guildwars2.com/v2/account?access_token=" + key)
                .then(response => response.json())
                .then(data =>
                    this.setState({
                        APIKey: key,
                        isLoading: false,
                        isCorrect: data.text ? false : true,
                        data: data
                    })
                );
        }
        else {
            this.setState({
                APIKey: undefined,
                isLoading: false,
                isCorrect: false,
                data: []
            });
        }
    }

    setAPIKey = (key: string) => {
        localStorage.setItem("API_Key", key);
        this.fetchData(key);
    }


    toggleSnackBar = (title: string, msg: string) => {
        this.setState({
            snackbarShow: true,
            snackbarTitle: title,
            snackbarContent: msg,
        }, async () => {
             await new Promise(resolve => setTimeout(resolve, 3000));
             this.setState({ snackbarShow: false })
        });
    }

}

export default Layout