import React from 'react';
import { Link } from "gatsby"

import { APIContext } from "../APIContext";

import { FiLogIn } from "react-icons/fi";
import { IoIosMenu } from "react-icons/io";



export class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
        isNavActive: true,
        editing: false,
        editLabel: ""
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, isNavActive: window.innerWidth > 768 });
  }

  render() {
    return (
        <header>
        <nav className="flex items-center justify-between flex-wrap bg-my_dark shadow-lg text-center">
          <Link to="/" className="px-2 md:px-4 text-lg tracking-wide font-medium font-display text-my_gray-lighter items-center">
            <img className="inline-block h-10" src={require("../images/book.svg")} />
            <span className="align-middle">{this.props.siteTitle}</span>
          </Link>

          <div className="md:hidden">
            <button className="flex text-my_gray text-2xl p-2" onClick={this.toggleMobileNav}>
              <IoIosMenu />
            </button>
          </div>

          <div className={"w-full flex-grow md:flex md:items-center md:w-auto" + (this.state.isNavActive ? " scale-on" : " scale-off")} >
            <div className="text-sm md:flex-grow">
              <Link to="/timers"
                className="nav-link md:inline-block md:mt-0 hover:bg-my_dark-lighter md:mx-2 md:px-2"
                activeClassName="text-my_gray-lighter bg-my_dark-lighter shadow-lg">
                Timers
              </Link>
              <Link to="/wvw"
                className="nav-link md:inline-block md:mt-0 hover:bg-my_dark-lighter md:mx-2 md:px-2"
                activeClassName="text-my_gray-lighter bg-my_dark-lighter shadow-lg">
                WvW
              </Link>
              <Link to="/account"
                className="nav-link md:inline-block md:mt-0 hover:bg-my_dark-lighter md:mx-2 md:px-2"
                activeClassName="text-my_gray-lighter bg-my_dark-lighter shadow-lg">
                Account
              </Link>
            </div>

            <div>
              {!this.state.editing && (
                <span className="nav-link flex items-center text-sm text-my_gray font-semibold cursor-pointer hover:bg-my_dark-lighter p-0 px-1 md:px-4 h-10" onClick={this.edit}>
                  {this.context.data.name ? this.context.data.name : "Enter API Key"}
                  {this.context.isCorrect ? <img className="inline-block h-10" src={"https://guilds.gw2w2w.com/" + this.context.data.guilds[0] + ".svg"} /> : null}
                </span>
              )}
              {this.state.editing && (
                <span className="nav-link p-0 flex items-center">
                  <input value={this.state.editLabel} onChange={this.onChange} className="text-sm appearance-none bg-my_dark-light h-10 text-my_gray p-1 leading-tight focus:outline-none w-full" type="text" placeholder="API Key" aria-label="API Key"></input>
                  <button onClick={this.onDoneEdit} className="hover:bg-my_dark-lighter p-1 text-my_gray-lighter h-10 border-l border-my_dark-lighter" type="button">
                    <FiLogIn />
                  </button>
                </span>
              )}
           </div>

          </div>


        </nav>
      </header>
    );
  }

  private edit = () => {
    this.setState({
      editing: true,
      editLabel: this.state.editLabel || this.context.APIKey
    });
  };

  private onChange = evt => {
    this.setState({
        editLabel: evt.target.value
    });
  };

  private onDoneEdit = () => {
    this.context.setAPIKey(this.state.editLabel);
    this.setState({
      editing: false,
      editLabel: ""
    });
  };

  toggleMobileNav = () => {
    console.log("toggle");
    this.setState(prevState => ({
      isNavActive: !prevState.isNavActive
    }));
  }

}

Header.contextType = APIContext;